import { Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.page.html',
  styleUrls: ['./cuenta.page.scss'],
})
export class CuentaPage implements OnInit {

  user:string;
  token:string;

  constructor( public alertController:AlertController, public navCtrl:NavController, private _api: ApiService ) { 
    this.user  = localStorage.getItem("user");
    this.token = localStorage.getItem("token");
  }

  ngOnInit() {
    
  }

  helpme(){
    window.open("https://api.whatsapp.com/send/?phone=8132430313&text=Hola, necesito ayuda.&app_absent=0");
  }

  async confirmCerrar() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Cerrar Sesión',
      message: '¿Realmente deseas cerrar sesión?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Si',
          id: 'confirm-button',
          handler: () => {
            console.log('Confirm Okay');
            localStorage.clear();
            this.navCtrl.navigateRoot('login');
          }
        }
      ]
    });

    await alert.present();
  }

  cuentaUpdate(){
    this.navCtrl.navigateForward('cuenta-update');
  }

  updateContrasena(){
    this.navCtrl.navigateForward('contrasena-update');
  }

  comandosEnviados(){
    this.navCtrl.navigateForward('envio-comandos');
  }

}
